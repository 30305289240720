import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';

import paths, { getRoute } from '@/router/paths';
import { drawerWidth } from '@/components/Layouts/Sidebar';

const useStyles = makeStyles((theme) => ({
  logoBox: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  logo: {
    display: 'block',
  },
  header: {
    boxShadow: ({ elevation }) => theme.shadows[elevation],
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: {
    color: theme.palette.common.white,
  },
  menuButton: {
    marginRight: theme.spacing(3.5),
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
}));

function Header({ handleDrawerToggle, elevation }) {
  const classes = useStyles({ elevation });
  const logo = getRoute('/images/logo-white/logo');

  return (
    <AppBar position="sticky" color="secondary" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            size="small"
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.logoBox}>
          <RouterLink to={paths.home}>
            <img
              className={classes.logo}
              width="150"
              height="24"
              srcSet={`
                ${logo}.png 1x,
                ${logo}@2x.png 2x
              `}
              src={`${logo}.png`}
              alt="Payconiq logo"
            />
          </RouterLink>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.defaultProps = {
  elevation: 1,
};

Header.propTypes = {
  elevation: PropTypes.number,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
